
import DashboardNotificationItem from "@/components/dashboard-area/DashboardNotificationItem.vue";
import DashboardNotificationServerItem from "@/components/dashboard-area/DashboardNotificationServerItem.vue";

import { LastSeenServerChannelsModule } from "@/store/modules/lastSeenServerChannel";
import { NotificationsModule } from "@/store/modules/notifications";

import { defineComponent } from "vue";
export default defineComponent({
  name: "DashboardArea",
  components: { DashboardNotificationItem, DashboardNotificationServerItem },
  computed: {
    dmNotifications(): any {
      return NotificationsModule.allDMNotifications;
    },
    serverChannelNotifications(): any {
      const allServerNotifications =
        LastSeenServerChannelsModule.allServerNotifications;
      const obj: any = {};

      for (let i = 0; i < allServerNotifications.length; i++) {
        const notification = allServerNotifications[i];
        if (!notification.server_id) continue;
        if (!obj[notification.server_id]) {
          obj[notification.server_id] = [notification];
          continue;
        }
        obj[notification.server_id].push(notification);
      }

      return obj;
    },
    notificatinExists(): any {
      return (
        this.dmNotifications.length ||
        Object.keys(this.serverChannelNotifications).length
      );
    },
  },
});
