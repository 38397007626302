
import Channel from "@/interfaces/Channel";
import {
  voiceChannelModule,
  CallParticipant,
} from "@/store/modules/voiceChannels";
import { DrawersModule } from "@/store/modules/drawers";
import { LastSeenServerChannelsModule } from "@/store/modules/lastSeenServerChannel";
import { MutedChannelsModule } from "@/store/modules/mutedChannels";
import { PopoutsModule } from "@/store/modules/popouts";
import { emojiURL } from "@/utils/emojiParser";
import { PropType } from "vue";
import CallTemplate from "./CallTemplate.vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: { CallTemplate },
  name: "ChannelTemplate",
  props: {
    channel: {
      type: Object as PropType<Channel>,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    callParticipants(): CallParticipant[] {
      return voiceChannelModule.callParticipants(this.channel.channelId);
    },
    path(): any {
      return `/app/servers/${this.channel.server_id}/${this.channel.channelId}`;
    },
    notificationExists(): any {
      return LastSeenServerChannelsModule.serverChannelNotification(
        this.channel.channelId
      );
    },
    isMuted(): any {
      return MutedChannelsModule.mutedChannels.includes(this.channel.channelId);
    },
    isChannelSelected(): any {
      return this.$route.params.channel_id === this.channel.channelId;
    },
    iconURL(): any {
      const icon = this.channel.icon;
      if (!icon) return null;
      const isCustom = icon.startsWith("g_") || icon.startsWith("c_");
      const isGif = icon.startsWith("g_");
      const customEmojiID = icon.split("_")[1];
      return emojiURL(isCustom ? customEmojiID : icon, {
        animated: this.hover,
        isCustom,
        isGif,
      });
    },
    channelStyle(): any {
      return {
        "--icon-url": this.iconURL && `url("${this.iconURL}")`,
      };
    },
  },
  methods: {
    closeDrawer() {
      DrawersModule.SetLeftDrawer(false);
    },
    showContext(event: any) {
      PopoutsModule.ShowPopout({
        id: "context",
        component: "ChannelContextMenu",
        key: this.channel.server_id + event.clientX + event.clientY,
        data: {
          x: event.clientX,
          y: event.clientY,
          server_id: this.channel.server_id,
          channelId: this.channel.channelId,
        },
      });
    },
  },
});
