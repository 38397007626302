
import { defineComponent } from "vue";

export default defineComponent({
  name: "NewsCard",
  data() {
    return {
      news: [] as Array<{ date: string; title: string; important: string[] }>,
    };
  },
  mounted() {
    this.fetchNews(); 
  },
  methods: {
    async fetchNews() {
      try {
        const newsData = [
          {
            date: "2024-10-15",
            title: "Hiring Developers and Moderators",
            important: [
              "We are looking for new developers and moderators.",
              "Join our team and apply at https://nertivia.com/employee",
            ],
          },
        ];
        this.news = newsData;
      } catch (err) {
        console.error("Failed to load news", err);
      }
    },
    formatDate(date: string) {
      const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
});
