
import {
  CallParticipant,
  voiceChannelModule,
} from "@/store/modules/voiceChannels";
import Avatar from "@/components/AvatarImage.vue";
import { PropType } from "vue";
import { defineComponent } from "vue";
import User from "@/interfaces/User";
import { MeModule } from "@/store/modules/me";
export default defineComponent({
  components: { Avatar },
  props: {
    participant: {
      type: Object as PropType<CallParticipant & { user: User }>,
      required: true,
    },
  },
  computed: {
    sharingScreen() {
      if (this.participant.user.id === MeModule.user.id) {
        return voiceChannelModule.videoStream;
      }
      return this.participant.videoStream;
    },
    streamingAudio() {
      if (this.participant.user.id === MeModule.user.id) {
        return voiceChannelModule.audioStream;
      }
      return this.participant.audioStream;
    },
  },
});
