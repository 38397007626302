
import Header from "@/components/Header.vue";
import NotificationCard from "./NotificationCard.vue";
import ProfileCard from "./ProfileCard.vue";
import NewsCard from "./NewsCard.vue"; // Importiere NewsCard
import { TabsModule } from "@/store/modules/tabs";

import { defineComponent } from "vue";
export default defineComponent({
  name: "DashboardArea",
  components: { NotificationCard, ProfileCard, Header, NewsCard },
  mounted() {
    TabsModule.setCurrentTab({ name: "Dashboard", icon: "dashboard" });
  },
});
