
import AvatarImage from "@/components/AvatarImage.vue";
import ChannelTemplate from "@/components/drawers/server-drawer/ChannelTemplate.vue";
import Notification from "@/interfaces/Notification";
import { ServersModule } from "@/store/modules/servers";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "DashboardNotificationItem",
  components: { AvatarImage, ChannelTemplate },
  props: {
    notifications: {
      type: Object as PropType<Notification>,
      required: true,
    },
    serverID: {
      type: String,
      required: true,
    },
  },
  computed: {
    serverDetails(): any {
      return ServersModule.servers[this.serverID];
    },
  },
});
