
import AvatarImage from "@/components/AvatarImage.vue";
import Channel from "@/interfaces/Channel";
import Server from "@/interfaces/Server";
import Notification from "@/interfaces/Notification";
import { ChannelsModule } from "@/store/modules/channels";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "DashboardNotificationItem",
  components: { AvatarImage },
  props: {
    channel: {
      type: Object as PropType<Channel & { server: Server }>,
      required: true,
    },
    dmNotification: {
      type: Object as PropType<Notification>,
      required: true,
    },
  },
  computed: {
    isServer(): any {
      return this.channel?.server;
    },
    serverDetails(): any {
      return {
        avatar: this.channel.server.avatar,
        seed: this.channel.server_id,
        channelName: this.channel.name,
        serverName: this.channel.server.name,
      };
    },
    details(): any {
      if (this.isServer) {
        return this.serverDetails;
      } else {
        return {
          avatar: this.dmNotification.sender.avatar,
          seed: this.dmNotification.sender.id,
          name: this.dmNotification.sender.username,
        };
      }
    },
  },
  methods: {
    onClick() {
      if (this.isServer) {
        this.$router.push(
          `/app/servers/${this.channel.server_id}/${this.channel.channelId}`
        );
        return;
      }
      ChannelsModule.LoadDmChannel(this.dmNotification.sender.id);
    },
  },
});
